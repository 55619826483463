import * as Sentry from "@sentry/browser";

Sentry.init({
    dsn: import.meta.env.VITE_SENTRY_DSN_PUBLIC,

    // This sets the sample rate to be 10%. You may want this to be 100% while
    // in development and sample at a lower rate in production
    replaysSessionSampleRate: parseFloat(import.meta.env.VITE_SENTRY_REPLAY_SESSION),

    // If the entire session is not sampled, use the below sample rate to sample
    // sessions when an error occurs.
    replaysOnErrorSampleRate: parseFloat(import.meta.env.VITE_SENTRY_REPLAY_ERROR),

    integrations: [
        Sentry.replayIntegration({
            // Additional SDK configuration goes in here, for example:
            maskAllText: false,
            blockAllMedia: false,
        }),
    ],

    beforeSend: function(event, hint) {
        // filter out UnhandledRejection errors that have no information
        if (event !== undefined && event.exception !== undefined && event.exception.values !== undefined
            && event.exception.values.length == 1) {
            var e = event.exception.values[0];
            if (e.type === 'UnhandledRejection' && e.value === 'Non-Error promise rejection captured with value: ') {
                return null;
            }
        }
    },
});